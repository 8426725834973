article{
    position: relative;
    height: 100%;
}
article .titleDiv{
    padding: 5px 15px;
    padding-top: 20px;
    background-image: linear-gradient(0deg, #000, rgba(0,0,0,0.7), rgba(0,0,0,0));
    color: white;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.catName{
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 1px 10px;
    color: #ffffff;
}

a{
    color: inherit !important;
    text-decoration: none !important;
}
.light span,
.listNews span{
    font-size: 14px;
    font-weight: bold;
}

@media(min-width: 787px){
    article h3{
        font-size: calc(0.2rem + 1.5vw) !important;
        line-height: 1.3;
        font-family: 'HelveticaBd';
    }

}

.listNews{
    height: inherit;
    margin: 8px 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(35,35,35,0.2);
}

@media(min-width: 1200px){
    .light h3{
        font-family: 'HelveticaReg' !important;
        font-size: 18px !important;
        text-decoration: none !important;
    }

    article h3{
        font-size: calc(0.2rem + 1vw) !important;
        line-height: 1.4;
        font-family: 'HelveticaBd';
    }

    .listNews h3{
        font-family: 'HelveticaReg' !important;
        font-size: 1rem !important;
        line-height: 1.2 !important;
    }

}

.imgCut{
    clip-path: polygon(100% 0, 100% 100%, 10% 100%, 0 85%, 0 0) !important;
}
@media(max-width: 786px){
    article h3{
        font-size: 20px !important;
    }
    .listNews h3{
        font-size: 17px !important;
    }

}

