/********* General *********/
:root{
    --green:#8a0075;
    --yallow : rgba(104, 0, 138, 0.51);
    --bs-success-rgb:138,0,117 !important;
}
.colorBg{
    background-color: var(--yallow);
}
body{
    background-color: #f5f8f8 !important;
}
.bg-success{
    background-color: var(--green) !important;
}
.bg-info{
    background-color: var(--yallow) !important;
}
.container{
    max-width: 100%;
}
.page-header-hidden {
    font-size: 0;
    width: 1px;
    height: 1px;
    display: inline-block;
    overflow: hidden;
    position: absolute !important;
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    clip: rect(1px, 1px, 1px, 1px);
}

.cat_header {
    width: 100%;
    margin-bottom: 20px;
    font-size: 24px;
    padding-right: 12px !important;
    position: relative;
    display: flex;
    align-items: center;
}

.cat_header a{
    position: relative;
    z-index: 1;
    padding: 0 10px;
    color: #232323;
    display: flex;
    font-weight: bold;
}

.cat_header a::after {
    content: " ";
    width: 32px;
    height: 32px;
    background-image: url("assets/images/go_arrow.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 13px 15px;
    margin-right: 10px;
}
.form-control:focus,
button:focus{
    border: 0px !important;
    box-shadow: none !important;
}
.header-search .form-control{
    font-size: 14px !important;
}
.cat_header::before {
    content: " ";
    top: 0;
    right: 0;
    display: block;
    position: absolute;
    background-clip: content-box;
    width: 6px;
    height: 100%;
    background: var(--green);
}


@media (min-width: 1500px) {
    .container{
        max-width: 1440px;
    }
}

.ads{
    background-color: #efefef;
    min-height: 120px;
    margin: 5px 0px 20px 0px;
}

.details{
    font-size: 18px !important;
}
.details p{
    margin: 6px 0px !important;
}
.text-justify{
    text-align: justify !important;
}
.details h3{
    font-size: 24px !important;
    font-weight: bold;
    padding-top: 10px !important;
}
.details figure{
    width: 100% !important;
    height: inherit !important;
}
.details .twitter-tweet{
    justify-content: center !important;
}
.details div{
    max-width: 100% !important;
    margin: 10px 0px !important;
    display: flex;
}

.details figure img{
    width: 100% !important;
    height:inherit !important;
}
/********* Home *********/
.navbar-toggler{
    background-color: transparent !important;
    border-radius: 0px !important;
    height: 100% !important;
    border:0px !important;
}
@media (max-width: 786px) {
    .middle-x{
        position: absolute;
        transform: translateX(50%);
        right: 50%;
        background-color:  rgba(255, 255, 255, 0.71);
        padding: 10px !important;
        display: inline-block;
        border-radius: 0px 0px 5px 5px;
        padding-top: 20px !important;
        box-shadow: 0px 2px 5px rgba(138, 0, 117, 0.13);
        z-index: 1 !important;
    }
    .nav-link{
        padding: 5px 0px !important;
        border-bottom:1px solid rgba(0, 0, 0, 0.1);
    }
}
