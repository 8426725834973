.topbar{
    background: #232323;
    height: 40px;
}
header .header{
    background: url("../../assets/images/header_bg.jpg") no-repeat center;
    background-size: cover;
}

.nav-link{
    font-size: 20px ;
    font-weight: bold;
    color: #000 !important;
}
.navlist{
    padding: 0px 0.4vw;
}
@media(min-width: 1400px){
    .navlist{
        padding: 0px 0.8vw;
    }
}
.navbar-nav{
    --bs-nav-link-padding-y:0 !important
}
header .dropdown-toggle::after{
    display: none;
}
header .dropdown .arrow{
    height: 100%;
    background-color: #343031;
}

header .dropdown .dropdown-menu{
    padding:0px !important;
    min-width: 200px !important;
    border-radius: 0px !important;
    border: 0px !important;
}
header .dropdown .dropdown-item{
    border-bottom: 1px solid #eaebed;
    align-items: center;
}
header .dropdown .dropdown-toggle:hover{
    cursor: pointer;
    background-color: #343031;
}
